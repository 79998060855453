<template>
    <a-card :bordered="false" class="card-area">
        <div class="search"><!-- 搜索区域 -->
            <div class="items" style="flex: none">
                <div class="one-item">
                    <a-select v-model="selvalue" placeholder="审核状态" style="width:100%" >
                        <a-select-option value="0">待审核</a-select-option>
                        <a-select-option value="1">已审核</a-select-option>
                    </a-select>
                </div>
                <div class="one-item">
                    <a-select v-model="selvalue" placeholder="提现状态" style="width:100%" >
                        <a-select-option value="0">提现中</a-select-option>
                        <a-select-option value="1">已提现</a-select-option>
                    </a-select>
                </div>
                <div class="one-item">
                    <a-input v-model="insearch" placeholder="请输入用户名查询"></a-input>
                </div>
            </div>
            <div class="operator">
                <a-button type="primary" @click="btsearch">查询</a-button>
            </div>
        </div>
      <a-table :dataSource="tableData" rowKey="id" size="small" :bordered="true" :columns="columns"
               :scroll="{ x: 1000 }">
        <template slot="imghead" slot-scope="text">
          <div v-if="text.uhead" class="img-list">
            <el-image style="width: 40px; height: 40px" :src="(text.uhead || '') | fullImgPath" fit="contain"
                      :preview-src-list="(text.uhead || '').split(',')"/>
          </div>
        </template>
        <template slot="fdoc" slot-scope="text,record">
          <a-tag :color="record.fdoc | dictName('postStatus', 'color')">
            {{ record.fdoc | dictName("postStatus") }}
          </a-tag>
        </template>
        <template slot="fstants" slot-scope="text,record">
          <a-tag :color="record.fstants | dictName('cashStatus', 'color')">
            {{ record.fstants | dictName("cashStatus") }}
          </a-tag>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-button v-if="record.fdoc == 0" type="primary" @click="doccash(record)">审核提现</a-button>
        </template>
      </a-table>
    </a-card>
  </template>
  <script>
  import {DICT} from "@/utils/dict";
  import {message} from "ant-design-vue";
  
  export default {
    name: "readerList",
    data() {
      return {
        tableData: [],
        value: '',
        showLoading: false,
        queryParams: {
          fwhere: "2",
          username: '',
          musername: '',
          orderNo: '',
          status: undefined,
          cstatus: undefined
        }
      };
    },
    created() {
      this.searchTable();
    },
    computed: {
        postStatus() {
        return DICT['postStatus']
      },
      cashStatus() {
        return DICT['cashStatus']
      },
      columns() {
        return [{
          title: "内码",
          width: 50,
          align: "center",
          dataIndex: "id",
        }, {
          title: "提现人ID",
          width: 80,
          align: "center",
          dataIndex: "uid",
        }, {
          title: "说明",
          width: 80,
          align: "center",
          dataIndex: "fmark",
        }, {
          title: "审核状态",
          width: 80,
          align: "center",
          dataIndex: "fdoc",
          scopedSlots: {customRender: "fdoc"}
        }, {
          title: "提现金额",
          width: 80,
          align: "center",
          dataIndex: "famg",
        },  {
          title: "提现状态",
          width: 80,
          align: "center",
          dataIndex: "fstants",
          scopedSlots: {customRender: "fstants"}
        }, {
          title: "申请时间",
          width: 80,
          align: "center",
          dataIndex: "createdate",
        },  {
          title: '操作',
          width: 160,
          align: 'center',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'}
        }];
      }
    },
    watch: {
      '$route.query.id': {
        handler() {
          if (this.$route.query.id) {
            this.queryParams.id = this.$route.query.id;
          }
          this.searchTable();
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      doccash(row) {
        message.info("审核提现-需要提现接口完成后才能继续")
        console.log(row)
      },
      reset() {
        this.queryParams = {
          fwhere: "2",
          username: '',
          orderNo: '',
          id: undefined,
          status: undefined
        }
      },
      searchTable() {
        let that = this;
        that.showLoading = true;
        that.$get("Stream/withdraws", {}).then((res) => {
          that.showLoading = false;
          that.tableData = res.data;
        });
      }
    }
  }
  </script>
  <style>
  .el-row {
    margin-bottom: 20px;
  }
  
  .el-col {
    border-radius: 4px;
  }
  
  .bg-purple-dark {
    background: #99a9bf;
  }
  
  .bg-purple {
    background: #d3dce6;
  }
  
  .bg-purple-light {
    background: #e5e9f2;
  }
  
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
  </style>
  